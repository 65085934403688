@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
%grid-2-columns {
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include gridle_state(md) {
		display: grid;
		grid-template-columns: repeat(2, calc(50% - 20px));
		grid-gap: 32px;
	}
}

%grid-3-columns {
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include gridle_state(md) {
		display: grid;
		grid-template-columns: repeat(3, 325px);
		grid-column-gap: 24px;
		grid-row-gap: 32px;
	}
}

.membership {
	$this: &;
	display: flex;
	position: relative;
	padding: 24px 16px;

	@include gridle_state(md) {
		padding: 40px 0;
	}

	&--connected {
		#{$this}__landing {
			margin-bottom: 32px;

			@include gridle_state(md) {
				display: flex;
				align-items: center;
			}
		}

		#{$this}__offers--landingPages,
		#{$this}__offers--merch {
			@extend %grid-2-columns;
		}
	}

	&--disconnected {
		#{$this}__offers--landingPages,
		#{$this}__offers--merch {
			@extend %grid-3-columns;
		}
	}

	.product-card {
		&__footer {
			> .relative-link {
				background: transparent; // eviter de prendre la couleur de RelativeLink
			}

			* {
				color: white !important;
			}
		}
	}

	// style pour réduire les products cards avec design d'aujourd'hui
	// A supprimer ou corriger une fois que l'on appliquera le nouveau design des products cards
	&--disconnected #{$this}__offers,
	&--connected #{$this}__offers:not(#{$this}__offers--merch):not(#{$this}__offers--landingPages) {
		.save-up-to__prefix,
		.from__value .amount__prefix,
		.product-time {
			@include gridle_state(sm) {
				font-size: 1.2rem;
			}
		}

		.deal__exclusive {
			@include gridle_state(sm) {
				font-size: 1.54rem;
				letter-spacing: 0.2rem;
			}
		}

		.deal__timer,
		.deal__price-type {
			@include gridle_state(sm) {
				min-width: auto; // eviter que le temps restant soit plus petit que le prix
			}
		}

		.product-card {
			&__picture {
				@include gridle_state(md) {
					height: 185px;

					.images-carousel-pp {
						&__image {
							height: 185px;
						}
					}
				}
			}

			&__action {
				display: none;
			}
		}

		.category {
			.icon {
				@include gridle_state(sm) {
					height: 13px;
					width: 13px;
				}
			}
		}

		.save-up-to__value {
			@include gridle_state(sm) {
				font-size: 2.4rem;
			}
		}

		.from__value .amount__value {
			font-size: 2rem;
		}
	}

	&__container {
		margin: 0 auto;
		width: 100%;
		max-width: 1024px;
		z-index: 1;
	}

	&__title {
		margin-bottom: 24px;
		line-height: 120%;
		color: white;

		@include gridle_state(md) {
			margin-bottom: 32px;
		}

		&--connected {
			text-align: left;
			flex: 1;

			@include gridle_state(md) {
				font-size: 2.6rem;
				margin-bottom: 0;
			}
		}

		&--disconnected {
			text-align: center;
		}
	}

	&__offers {
		@extend %grid-3-columns;

		width: 100%;
		overflow: hidden;

		&--landingPages,
		&--merch {
			@extend %grid-2-columns;
		}
	}

	&__advantages {
		display: grid;
		grid-gap: 16px;
		padding-left: 24px;
		padding-right: 24px;
		width: 100%;
		box-sizing: border-box;

		@include gridle_state(md) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&__advantage {
		&-item {
			display: flex;
			align-items: center;
		}

		&-picto {
			margin-right: 16px;
			border-radius: 99px;
			border: 0.8px solid rgba(white, 0.3);
			padding: 8px;
			width: 40px;
			height: 40px;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba(255, 255, 255, 0.13);

			@include gridle_state(md) {
				width: 43px;
				height: 43px;
			}

			img {
				width: 24px;
				height: 24px;

				@include gridle_state(md) {
					width: 27px;
					height: 27px;
				}
			}
		}

		&-text {
			flex: 1 1 100%;
			color: white;
		}
	}

	&__cta {
		display: flex;
		justify-content: center;
		margin: 24px 0 0 0;
		padding-left: 24px;
		padding-right: 24px;
		width: 100%;
		box-sizing: border-box;

		@include gridle_state(md) {
			margin: 32px auto 0;
		}

		.button {
			text-transform: initial;

			@include gridle_state(md) {
				padding: 0 32px;
				width: fit-content;
				min-width: 250px;
			}
		}
	}

	&__all-sales {
		width: 100%;
		padding: 0 16px;
		box-sizing: border-box;
		margin-top: 24px;

		.relative-link {
			@extend %text-bold;
			font-size: 1.6rem;
			line-height: 140%;
			color: white;
			border: 1px solid white;
			background: transparent;
			box-shadow: none;

			svg {
				margin-left: 8px;
			}
		}

		@include gridle_state(md) {
			margin: 0;
			padding: 0;
			width: fit-content;

			.relative-link {
				border: none;

				svg {
					margin-left: 0;
				}
			}
		}
	}
}
